<template>
    <div>
        <div class="row">
            <div class="col-md-12">
                <KTCard>
                    <template v-slot:title>
                        {{operationTitle}}
                        <p><small>Set Terms and Conditions</small></p>
                    </template>
                    <template v-slot:body>
                        <div>
                            <div class="terms-operation">
                                <b-container fluid>
                                    <form @submit.prevent="handleSubmitOperation" autocomplete="off">
                                        <b-row>
                                            <b-col class="p-0" cols="12" md="12" lg="12" sm="12">
                                                <b-row>
                                                    <b-col sm="12">
                                                        <b-form-group
                                                            label="Note *"
                                                            label-for="note"
                                                            :invalid-feedback="formErrors.first('note')"
                                                        >
                                                            <ckeditor
                                                                :config="editorDefaultConfig"
                                                                v-model="formFields.note">
                                                            </ckeditor>
                                                            <b-form-invalid-feedback
                                                                :state="(!(formErrors.has('note')))">
                                                                {{formErrors.first('note')}}
                                                            </b-form-invalid-feedback>
                                                        </b-form-group>
                                                    </b-col><!--/b-col-->
                                                </b-row>
                                                <hr/>
                                                <b-row class="operation-footer">
                                                    <b-col sm="12">
                                                        <b-button
                                                            size="sm"
                                                            type="submit"
                                                            variant="primary"
                                                            :disabled="global.pendingRequests > 0"
                                                        >
                                                            <i v-show="global.pendingRequests > 0"
                                                               class="fa fa-spin fa-spinner"></i>
                                                            <i class="fa fa-save fa-sm"></i>
                                                            Save
                                                        </b-button>
                                                    </b-col>
                                                </b-row>
                                            </b-col><!--/b-col-->
                                        </b-row><!--/b-row-->
                                    </form><!--/form-->
                                </b-container><!--/b-container-->
                            </div><!--/.terms-operation-->
                        </div>
                    </template>
                </KTCard>
            </div>
        </div>
    </div>
</template>

<script>
    import {mapState} from 'vuex'
    import KTCard from "@/view/content/Card.vue";
    import ListingMixin from "@/assets/js/components/ListingMixin";
    import Error from "@/core/services/Error";
    import {request} from "@/core/services/Request";
    import {getPropertyId} from "@/util/globalFunction";

    const FORM_STATE = {
        source_name: null,

        _method: 'post',
    };

    export default {
        data() {
            return {
                operationTitle: 'Set Terms and Conditions',
                formFields: {...FORM_STATE},
                formErrors: new Error({}),
                dropdowns: {
                    properties: []
                },
                editorDefaultConfig: {
                    extraPlugins: ['colorbutton,justify'],
                    toolbarGroups: [
                        {name: "document", groups: ["mode", "document", "doctools"]},
                        {name: "clipboard", groups: ["clipboard", "undo"]},
                        {
                            name: "editing",
                            groups: ["find", "selection", "spellchecker", "editing"]
                        },
                        {name: "basicstyles", groups: ["basicstyles", "cleanup"]},
                        {
                            name: "paragraph",
                            groups: ["list", "indent", "blocks", "align", "bidi", "paragraph"]
                        },
                        {name: "links", groups: ["links"]},
                        {name: "styles", groups: ["styles"]},
                        {name: 'colors'},
                        {name: "tools", groups: ["tools"]},
                        {name: "others", groups: ["others"]},
                    ]
                }
            }
        },
        components: {
            KTCard
        },
        mounted() {
            if (getPropertyId()) {
                this.getDetail(getPropertyId())
            }
        },
        methods: {
            afterCloseOperation() {
                this.formFields = {...FORM_STATE}
            },
            async handleSubmitOperation() {
                this.formErrors = new Error({})
                try {
                    const response = await request({
                        url: this.formFields.id ? 'term/conditions/update' : 'term/conditions/create',
                        method: 'post',
                        data: this.formFields,
                    })
                    if (this.formFields.id) {
                        this.$global.itemUpdated()
                    } else {
                        this.$global.itemAdded()
                    }
                } catch (error) {
                    if (error.request && error.request.status && error.request.status === 422) {
                        this.formErrors = new Error(JSON.parse(error.request.responseText).errors)
                        return false
                    }
                    this.$global.handleServerError(error)
                }

            },
            async getDetail(propertyId) {
                try {
                    const response = await request({
                        method: 'get',
                        url: `/term/conditions/detail/${propertyId}`,
                    })
                    const {data} = response.data
                    if (data) {
                        this.formFields = {
                            ...data,
                            property_id: data.property ? data.property.id : null
                        }
                    }
                } catch (e) {
                    this.$global.itemEditFails()
                    this.formFields = {...FORM_STATE}
                }
            },
        },
        computed: {
            ...mapState([
                'global',
            ]),
        },
    }
</script>
